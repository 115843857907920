<template>
    <div class="flex flex-col h-screen w-screen fixed overflow-x-hidden" :[themeAttr]="settings?.theme" >

        <Navbar />

        <div class="flex-1 mx-4 container mx-auto pt-4 lg:pt-8">
            <slot/>
        </div>

        <Footer class="sticky bottom-0 mt-8"/>
    </div>
</template>

<script lang="ts" setup>
const {settings} = getUser()
const themeAttr = 'data-theme'

</script>

