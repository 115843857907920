<script setup lang="ts">
const {user, trips, isLoggedIn, logout} = getUser()
const {enableRecipes} = useAppConfig();

</script>

<template>
    <div class="navbar bg-primary text-primary-content flex-shrink-0 p-0 sticky top-0 z-[1]">
        <div class="flex-1">
            <nuxt-link to="/" class="btn btn-ghost normal-case text-xl">{{$t('site.title')}}</nuxt-link>
        </div>
        <ul class="menu menu-horizontal px-1">
            <li>
                <nuxt-link to="/explore/trips" active-class="underline">{{$t('links.explore')}}</nuxt-link>
            </li>
            <li>
                <nuxt-link to="/explore/foods" active-class="underline">{{$t('links.foods')}}</nuxt-link>
            </li>
            <li v-if="enableRecipes">
                <nuxt-link to="/explore/recipes">{{$t('links.recipes')}}</nuxt-link>
            </li>
            <li v-if="isLoggedIn && enableRecipes">
                <nuxt-link to="/recipes">{{$t('links.myRecipes')}}</nuxt-link>
            </li>
            <li v-if="isLoggedIn" class="dropdown dropdown-hover dropdown-bottom dropdown-end">
                <nuxt-link to="/trips" custom v-slot="{ navigate }" active-class="underline">
                    <label @click="navigate" @keypress.enter="navigate" role="link" tabindex="0" class="m-1">{{ $t('links.myTrips')}}</label>
                </nuxt-link>
                <ul tabindex="0"
                    class="dropdown-content z-[10] menu p-2 shadow bg-base-100 text-base-content rounded-box min-w-52">
                    <li v-for="trip in trips" :key="trip.id">
                        <nuxt-link :to="`/trips/${trip.id}`">{{ trip.description }}</nuxt-link>
                    </li>
                    <li class="menu-title" v-if="trips.length == 0">{{$t('links.noTrips')}}</li>
                    <div v-if="trips.length" class="divider m-0"></div>
                    <li>
                        <nuxt-link to="/" class="font-bold">+ {{$t('links.addTrip')}}</nuxt-link>
                    </li>
                </ul>
            </li>

            <li v-if="isLoggedIn" class="dropdown dropdown-hover dropdown-bottom dropdown-end">
                    <label tabindex="0" class="m-1">{{ user?.username }}</label>
                <ul tabindex="0"
                    class="dropdown-content z-[10] menu p-2 shadow bg-base-100 text-base-content rounded-box min-w-52">
                    <li><nuxt-link to="/settings">{{$t('links.settings')}}</nuxt-link></li>
                    <div class="divider m-0"></div>
                    <li @click="logout()"><a>{{$t('links.logout')}} {{ user?.username }}</a></li>
                </ul>
            </li>
            <li v-if="!isLoggedIn">
                <nuxt-link to="/auth/login">{{$t('links.loginCreateAccount')}}</nuxt-link>
            </li>
        </ul>
    </div>
</template>

<style scoped>

</style>
